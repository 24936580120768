<template>
  <v-row class="align-center d-flex h-100 mt-md-n16">
    <v-col class="mx-auto" lg="4" md="7">
      <v-card class="card-shadow border-radius-xl py-1">
        <div
          style="background-color: #243b5e"
          class="
            card-padding
            text-center
            mt-n7
            card-shadow
            border-radius-lg
            mx-4
          "
        >
          <h4 class="text-h4 font-weight-bolder text-white mb-2">
            HKC Management
          </h4>
          <p class="mb-1 text-white font-weight-light text-sm">
            Ingrese su correo electrónico / usuario y contraseña para iniciar
            sesión
          </p>
        </div>
        <div class="card-padding text-center">
          <v-text-field
            v-model="model.username"
            class="font-size-input input-style mb-5"
            dense
            hide-details
            label="Usuario o Email"
            outlined
          ></v-text-field>

          <v-text-field
            v-model="model.password"
            class="font-size-input input-style mb-5"
            dense
            hide-details
            label="Contraseña"
            outlined
            type="password"
            @keyup.enter="login()"
          ></v-text-field>

          <v-alert
            v-if="showError"
            closable
            variant="outlined"
            type="error"
            border="top"
            density="compact"
          >
            Usuario o contraseña incorrecto
          </v-alert>

          <v-btn
            @click="login()"
            :loading="loading"
            :ripple="false"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              py-2
              px-6
              me-2
              mt-6
              mb-2
              w-100
            "
            color="primary"
            elevation="0"
            height="43"
            small
          >
            Iniciar sesión
          </v-btn>

          <label class="text--disabled caption"> 0.5v </label>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import loginService from "@/services/login.service";
import router from "@/router";

export default {
  name: "Login",
  data() {
    return {
      model: {
        username: "",
        password: "",
      },
      loading: false,
      showError: false,
      checkbox: false,
    };
  },
  methods: {
    login() {
      this.showError = false;
      this.loading = true;

      loginService.login(this.model.username, this.model.password).then((d) => {
        this.loading = false;

        if (d.success === true) {
          router.push("/dashboard");
        } else {
          this.showError = true;
        }

        setTimeout(() => {
          this.showError = false;
        }, 5000);
      });
    },
  },
};
</script>

<style scoped>
</style>
